import { once } from 'underscore'
import { getAirbrakeClient } from 'utils/airbrake'

/* global MathJax, $ */
const MathJaxConfig = {
  startup: {
    typeset: false,
  },
  tex: {
    inlineMath: [
      ['$', '$'],
      ['\\(', '\\)'],
    ],
    processEscapes: true,
  },
  options: {
    menuOptions: {
      settings: {
        inTabOrder: false,
      },
    },
  },
}

// how long to wait for MathJax to come up (ms)
const timeOut = 10000

const retry = async (fn, timeOut = 10000, delay = 50) => {
  do {
    try {
      return await fn()
    } catch (error) {
      if (timeOut <= 0) {
        return Promise.reject(new Error(error))
      }
    }
    await new Promise((resolve) => setTimeout(resolve, delay))
    timeOut -= delay
  } while (timeOut >= 0)
}

const checkMathjax = () => {
  if (MathJax && MathJax.typesetPromise) {
    return Promise.resolve(MathJax.typesetPromise)
  } else {
    return Promise.reject(new Error('MathJax.typesetPromise is not found'))
  }
}

const airbrake = getAirbrakeClient()
const notify = once((msg) => airbrake?.notify(msg))

export const loadMathJax = async () => {
  const existing = document.getElementById('mathjax-src')
  if (!existing) {
    window.MathJax = MathJaxConfig

    const script = document.createElement('script')
    script.src = 'https://cdn.jsdelivr.net/npm/mathjax@3/es5/tex-chtml-full.js'
    script.id = 'mathjax-src'
    script.async = true
    document.head.appendChild(script)
  }

  return await retry(checkMathjax, timeOut).catch(() => {
    notify(
      `MathJax load error: MathJax.typesetPromise still undefined after ${timeOut}ms`
    )
    $('#missing-mathjax-alert').removeClass('d-none').addClass('d-inline-block')
  })
}
