import Backbone from 'backbone'
import BaseModel from 'models/base'
import CreditUsage from 'models/credit_usage'
import compact from 'underscore'
import { pluralize } from 'utils/common'

export default class Subscription extends BaseModel {
  url () {
    if (this.id) {
      return `/subscriptions/${this.id}`
    } else {
      return '/subscriptions'
    }
  }

  usageLabel () {
    const usage = compact([
      (this.get('cancel_at_period_end') && 'cancels at end of period') || null,
      this.get('credits_available') &&
        `${this.get('credits_available')} ${pluralize(
          'credit',
          this.get('credits_available')
        )} left`,
    ]).join(' &bullet; ')

    return usage
  }

  parse (resp, options) {
    super.parse(resp, options)
    this.currentUsage = this.hasOne('current_usage', CreditUsage, resp)
    this.paymentIntent = this.hasOne('payment_intent', Backbone.Model, resp)
    return resp
  }

  requiresAction () {
    return (
      this.paymentIntent?.get('status') === 'requires_action' ||
      this.paymentIntent?.get('status') === 'requires_payment_method'
    )
  }

  inTrialPeriod () {
    return this.get('status') === 'trialing'
  }

  isActive () {
    return this.get('status') === 'active' || this.get('status') === 'trialing'
  }

  renewalVerb () {
    if (this.get('cancel_at_period_end')) {
      return 'end'
    } else {
      return 'renew'
    }
  }

  cancel (immediate = false, callback = null) {
    if (immediate) {
      this.destroy({
        url: `${this.url()}?immediate=true`,
        success: (model, resp) => {
          callback?.()
        },
      })
    } else {
      this.command(`${this.url()}?immediate=false`, {
        method: 'DELETE',
        success: (subscription, resp) => {
          this.set(resp, { parse: true })
          callback?.()
        },
      })
    }
  }
}
