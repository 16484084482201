/* global $ */
import Noty from 'noty'
import { defaults, defer } from 'underscore'
import EdfinityCookie from 'models/cookie'
import { errorMessagesToHtml } from 'helpers/view'

export const flash = (type, message) => {
  const cookie = new EdfinityCookie({ namespace: 'flash' })
  cookie.set('type', type)
  cookie.set('message', message)
}

// message can be string or noty options
export const flashMessage = (message, type = 'alert', options = {}) => {
  const buttonHtml = ''

  if (typeof message === 'object') {
    message.message += buttonHtml
    options = message
  } else {
    options.text = message + buttonHtml
  }

  defaults(options, {
    layout: 'topCenter',
    closeWith: ['click'],
    timeout: type === 'error' ? 12000 : 3000,
    type,
  })

  const instance = noty(options)

  // We need this when we show a flash message containing a link while showing a modal.
  // Ally disables event handling outside the modal; this reenables click events for the link.
  if (options.makeLinkClickable) {
    defer(() => {
      $('.noty_body a').get(0).style.pointerEvents = 'auto'
    })
  }

  return instance
}

export const flashWaitingMessage = (options) => {
  return noty({
    text: `${
      options.hideSpinner
        ? ''
        : '<span class="fa fa-spinner fa-spin" aria-hidden="true"></span>'
    } <span class='loading-message'>${options.message}</span>`,
    layout: 'bottomCenter',
  })
}

export const noty = (options) => {
  return new Noty(options).show()
}

export const notySync = (model, fn, options = {}) => {
  defaults(options, { message: 'Running...' })
  const progress = flashWaitingMessage(options)
  fn?.(progress)
  model.on('sync error command', () => progress.close())
}

// flash a JSON-supplied error; error should conform to Rails format as follows:
// { message: 'message'
//     errors: {
//       'field1': 'error message',
//       'field2': 'error message2'
//     }
// }
export const flashError = (error, defaultMessage) => {
  if (error?.responseText) {
    error = JSON.parse(error.responseText)
  }
  return flashMessage(
    {
      text: errorMessagesToHtml(error, defaultMessage),
    },
    'error'
  )
}
