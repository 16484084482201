// sets cookie for automatic timezone detection server-side
// https://github.com/kbaum/browser-timezone-rails/blob/master/app/assets/javascripts/browser_timezone_rails/set_time_zone.js.erb
module.exports = function () {
  if (location.protocol == 'https:')
    window.document.cookie = `browser.timezone=${
      Intl.DateTimeFormat().resolvedOptions().timeZone
    };path=/;secure;SameSite=None;Partitioned`
  else
    window.document.cookie = `browser.timezone=${
      Intl.DateTimeFormat().resolvedOptions().timeZone
    };path=/`
}
