import Noty from 'noty'

const notifyOffline = function () {
  const noty = new Noty({
    type: 'error',
    layout: 'bottomLeft',
    text: `
      <div class="d-flex align-items-center">
        <span
          class="fa fa-exclamation-triangle"
          aria-hidden="true"
        ></span>
        <span class="ml-2"><span class="font-weight-bold">You are offline.</span><br />Changes made will not be saved.</span>
      </div>`,
    timeout: false,
    closeWith: [],
  })
  noty.show()

  return noty
}

const notifyOnline = function () {
  new Noty({
    type: 'success',
    layout: 'bottomLeft',
    text: 'You are back online!',
    timeout: 5000,
  }).show()
}

const handleOfflineEvent = () => {
  const noty = notifyOffline()
  const handleOnline = () => {
    noty.close()
    window.removeEventListener('online', handleOnline)
    notifyOnline()
  }

  window.addEventListener('online', handleOnline)
}

export const listenForOfflineEvents = () => {
  window.addEventListener('offline', handleOfflineEvent)
}
