import { any } from 'underscore'
import AirbrakeClient from 'airbrake-js'

let airbrake

export const getAirbrakeClient = () => {
  if (!airbrake && process.env.AIRBRAKE_PROJECT_ID) {
    airbrake = new AirbrakeClient({
      projectId: process.env.AIRBRAKE_PROJECT_ID,
      projectKey: process.env.AIRBRAKE_API_KEY,
    })

    airbrake.addFilter((notice) => {
      var messagesToIgnore = [
        'Loading chunk 692 failed',
        'l.LegacyGlobal.should_do_lastpass_here is not a function',
        'Extension context invalidated',
        'mce-visual-caret-hidden',
        'Desmos',
        'MathJax.typesetPromise still undefined',
        'SIGHUP',
        'Sidekiq::Shutdown',
        'ChunkLoadError: Unhandled Promise Rejection',
        'Cannot redefine property: googletag',
        'js-agent.newrelic.com',
      ]

      if (
        messagesToIgnore.some(
          (item) => notice.errors[0].message.indexOf(item) > -1
        )
      )
        return null

      if (
        any(
          notice.errors[0].backtrace,
          (line) => line.file.indexOf('safari-extension') > -1
        )
      )
        return null
      notice.context.environment = process.env.RAILS_ENV
      return notice
    })
  }

  return airbrake
}
